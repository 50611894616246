<template>
  <v-container>
    <!-- <p class="display-1 text--primary">
      <v-btn class="mr-5 primary" text icon small dark @click="$router.go(-1)"
        ><v-icon>mdi-chevron-left</v-icon></v-btn
      >
      {{ $route.params.nomeCampanha }}
      - {{ $route.params.inicioCampanha | BrazilianStandardDate }} a
      {{ $route.params.fimCampanha | BrazilianStandardDate }}
    </p> -->

    <div v-if="loading" class="mt-5">
      <h3 class="text-center mb-2">Carregando Dados...</h3>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>
    <div v-if="tabs[0].status && tabs[1].status && tabs[2].status">
      <h2 class="text-center mb-2">Não há dados de metas para exibir</h2>
    </div>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="active_tab">
          <v-tab
            @click="$root.$emit('click:tabMetas', tab.idTipoMeta)"
            v-for="tab in newTabs"
            :key="tab.index"
            >{{ tab.tabTitle }}</v-tab
          >

          <v-tab-item v-for="tab in newTabs" :key="tab.index">
            <v-row>
              <v-col>
                <component :idTipoMeta="tab.idTipoMeta" :is="tab.component" />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MetaEmpresas from "@/components/campanha/metas/MetaEmpresa.vue";
import MetaEquipe from "@/components/campanha/metas/MetaEquipe.vue";
import MetaIndividual from "@/components/campanha/metas/MetaIndividual.vue";
import dateMixin from "@/mixins/dateMixin";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "tipo-metas",

  components: {
    MetaEmpresas,
    MetaEquipe,
    MetaIndividual
  },

  mixins: [dateMixin],

  data() {
    return {
      loading: false,
      active_tab: 0,
      tabs: [
        {
          component: "meta-empresas",
          tabTitle: "Meta de Empresas",
          index: 0,
          status: false,
          idTipoMeta: 4
        },
        {
          component: "meta-equipe",
          tabTitle: "Meta de Equipe",
          index: 1,
          status: false,
          idTipoMeta: 3
        },
        {
          component: "meta-individual",
          tabTitle: "Meta Individual",
          index: 2,
          status: false,
          idTipoMeta: 2
        }
      ],
      newTabs: []
    };
  },

  async mounted() {
    this.loading = true;
    this.actionEmpresasParticipantes();
    this.fetchPublicoAlvoCampanha(this.$route.params.idCampanha);
    await this.fetchMetaEmpresa({
      id_tipo_meta: 4,
      id_campanha: this.getCurrentCampanha,
      per_page: -1
    }),
      await this.fetchMetaEquipe({
        id_tipo_meta: 3,
        id_campanha: this.getCurrentCampanha,
        per_page: -1
      }),
      await this.fetchMetaIndividual({
        id_tipo_meta: 2,
        id_campanha: this.getCurrentCampanha,
        per_page: -1
      }).then(() => {
        setTimeout(() => {
          this.loading = false;
        }, 100);
      }),
      this.renderTab();
  },

  computed: {
    ...mapGetters({
      getCurrentCampanha: "campanha/getCurrentCampanha",
      getMetaEmpresa: "campanha/getMetaEmpresa",
      getMetaEquipe: "campanha/getMetaEquipe",
      getMetaIndivual: "campanha/getMetaIndividual"
    })
  },

  methods: {
    ...mapActions({
      actionEmpresasParticipantes: "campanha/actionEmpresasParticipantes",
      fetchPublicoAlvoCampanha: "campanha/fetchPublicoAlvoCampanha",
      fetchMetaEmpresa: "campanha/fetchMetaEmpresa",
      fetchMetaEquipe: "campanha/fetchMetaEquipe",
      fetchMetaIndividual: "campanha/fetchMetaIndividual"
    }),

    renderTab() {
      this.tabs[0].status = !this.getMetaEmpresa?.data?.length;
      this.tabs[1].status = !this.getMetaEquipe?.data?.length;
      this.tabs[2].status = !this.getMetaIndivual?.data?.length;
      this.tabs.filter(item => {
        if (item.status != true) {
          return this.newTabs.push(item);
        }
      });
    }
  }
};
</script>

<style scoped>
.v-tab--active {
  background-color: white;
}
</style>
